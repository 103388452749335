<template>
  <v-data-table
    :headers="cartHeader"
    :items="items"
    mobile-breakpoint="0"
    :hide-default-footer="items.length <= 5"
  >
    <template v-slot:item.brand="{ item }">
      <v-layout>
        <v-flex shrink>
          <div class="text-start txt-bigger darkblue-color">
            {{ item.group_part ? item.group_part.motor_model.brand.name : '' }}
          </div>
          <div class="text-start darkblue-color font-weight-light">
            {{ item.group_part ? item.group_part.motor_model.name : '' }}
          </div>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:item.category="{ item }">
      <v-layout>
        <v-flex shrink>
          <div class="text-start txt-bigger darkblue-color">
            {{ item.group_part ? getGroupPartName(item.group_part.type) : '' }}
          </div>
          <div class="text-start darkblue-color font-weight-light">
            {{ item.group_part ? item.group_part.name : '' }}
          </div>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:item.number="{ item }">
      <v-btn outlined fab x-small
        ><h3>
          {{
            item.group_part_part.length > 0
              ? item.group_part_part[0].number
              : ''
          }}
        </h3></v-btn
      >
    </template>
    <template v-slot:item.product="{ item }">
      <v-layout>
        <v-flex shrink>
          <div class="text-start txt-bigger darkblue-color">
            {{ item.part && item.part.name ? item.part.name : '-' }}
          </div>
          <div class="text-start darkblue-color font-weight-light">
            SKU : {{ item.sku }}
          </div>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:item.price="{ item }">
      <div>
        {{ toCurrencyLable(currency.currency) }}
        {{
          formatNumber(
            specificExchangeRate(item.price_per_piece, currency.currency),
            2
          )
        }}
      </div>
    </template>
    <template v-slot:item.quantity="{ item }">
      <MiniQuantitySelector
        :value="item.quantity"
        :lock="lock"
        :quantity-exceed="
          item.part && item.part.product_stock.stock
            ? item.quantity >= item.part.product_stock.stock
            : true
        "
        @increase="eventIncreaseItem(item)"
        @decrease="eventDecreaseItem(item)"
      ></MiniQuantitySelector>
    </template>
    <template v-slot:item.total="{ item }">
      <div>
        {{ toCurrencyLable(currency.currency) }}
        {{
          formatNumber(
            specificExchangeRate(item.total_price, currency.currency),
            2
          )
        }}
      </div>
    </template>
    <template v-slot:item.remove="{ item }">
      <v-btn icon small @click="eventRemoveItem(item)">
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import MiniQuantitySelector from './input/MiniQuantitySelector'
import { formatNumber } from '@/plugins/global-funcs'
import { part_type } from '../config'
import { currency_symbol } from '../config'
export default {
  name: 'CartTable',
  components: {
    MiniQuantitySelector
  },
  props: {
    items: {
      default: []
    },
    deletable: {
      default: true
    },
    lock: {
      default: false
    },
    exchangeRate: {
      default: function () {
        return {}
      }
    },
    currency: {
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      partType: part_type,
      currency_symbol: currency_symbol,
      cartHeader: this.deletable
        ? [
            {
              text: 'Brand',
              value: 'brand',
              sortable: false
            },
            {
              text: 'Category',
              value: 'category',
              sortable: false
            },
            {
              text: this.$t('partListTable.number'),
              value: 'number',
              align: 'center',
              sortable: false
            },
            {
              text: this.$t('partListTable.product'),
              value: 'product',
              sortable: false
            },
            {
              text: this.$t('partListTable.price'),
              value: 'price',
              align: 'center',
              sortable: false
            },
            {
              text: this.$t('partListTable.amount'),
              value: 'quantity',
              align: 'center',
              sortable: false
            },
            {
              text: this.$t('partListTable.totalPrice'),
              value: 'total',
              width: '140px',
              align: 'center',
              sortable: false
            },
            {
              text: '',
              value: 'remove',
              width: '60px',
              align: 'center',
              sortable: false
            }
          ]
        : [
            {
              text: this.$t('partListTable.product'),
              value: 'product',
              sortable: false
            },
            {
              text: this.$t('partListTable.amount'),
              value: 'quantity',
              width: '140px',
              align: 'center',
              sortable: false
            },
            {
              text: this.$t('partListTable.totalPrice'),
              value: 'total',
              width: '140px',
              align: 'center',
              sortable: false
            }
          ],
      formatNumber: formatNumber
    }
  },
  methods: {
    getGroupPartName(id) {
      const partTypeModel = this.partType.find((x) => x.id === parseInt(id))
      return partTypeModel ? partTypeModel.name : ''
    },
    specificExchangeRate(thb_price, currency) {
      if(currency === 'USD') {
        return Math.ceil((this.exchangeRate[currency].sell_rate * thb_price)*10)/10
      }
      else return Math.ceil(this.exchangeRate[currency].sell_rate * thb_price);
    },
    toCurrencyLable(currency) {
      return this.currency_symbol[currency]
    },
    eventIncreaseItem(item) {
      this.$emit('increaseItem', item)
    },
    eventDecreaseItem(item) {
      this.$emit('decreaseItem', item)
    },
    eventRemoveItem(item) {
      this.$emit('removeItem', item)
    }
  }
}
</script>

<style scoped>
table.v-table thead th {
  font-size: 13px !important;
}
</style>
