<template>
  <div class="fill-height py-5 py-md-10">
    <Loading :loading="isLoading"></Loading>
    <v-row>
      <v-col class="col-lg-3 col-sm-6">
        <v-img :src="require('../assets/img/titlebar.png')">
          <div class="cust-color ml-15 mt-3 text-center">
            <h2>MY CART</h2>
          </div>
        </v-img>
      </v-col>
    </v-row>
    <div
      :class="[$vuetify.breakpoint.smAndDown ? 'container' : 'cart-layout']"
      v-if="accessToken"
    >
      <v-layout mt-7 :column="$vuetify.breakpoint.smAndDown">
        <v-flex class="mb-5 mb-md-0 px-3">
          <CartTable
            :items="inCart"
            :currency="currency"
            :exchangeRate="exchangeRate"
            @increaseItem="increaseItem"
            @decreaseItem="decreaseItem"
            @removeItem="removeItem"
          ></CartTable>
          <div class="mt-3">
            <span
              class="cust-color pointer"
              @click="$router.push({ name: 'Home' })"
            >
              <v-icon class="cust-color">chevron_left</v-icon>
              <span>{{$t('checkoutStep.continueShopping')}}</span>
            </span>
          </div>
        </v-flex>
        <v-flex class="form-color px-3" shrink lg3 style="min-width: 350px">
          <div class="txt-head text-center darkblue-color mb-4">
            ORDER SUMMARY
          </div>
          <v-divider></v-divider>
          <v-layout justify-space-between align-center mb-2>
            <v-flex shrink>
              {{ inCart.length }}
              {{ inCart.length > 1 ? 'items' : 'item' }}
            </v-flex>
            <v-flex shrink class="txt-bigger font-weight-light darkblue-color">
              {{ toCurrencyLable(currency.currency) }}
              {{ formatNumber(subTotal, 2) }}
            </v-flex>
          </v-layout>
          <v-layout justify-space-between align-center mb-2>
            <v-flex shrink>Total Product Cost :</v-flex>
            <v-flex shrink class="txt-bigger font-weight-light darkblue-color">
              {{ toCurrencyLable(currency.currency) }}
              {{
                formatNumber(totalIncludedTax, 2)
              }}
            </v-flex>
          </v-layout>
          <!-- <v-layout justify-space-between align-center mb-2>
            <v-flex shrink>Shipping Cost : </v-flex>
            <v-flex shrink class="txt-bigger font-weight-light darkblue-color">
              {{
                shippingCost === 0
                  ? noAddressText
                  : toCurrencyLable(currency.currency)
              }}
              {{
                formatNumber(
                  specificExchangeRate(shippingCost, currency.currency),
                  2
                )
              }}
            </v-flex>
          </v-layout> -->
          <!-- <v-layout justify-space-between align-center mb-5>
            <v-flex shrink>VAT : </v-flex>
            <v-flex shrink class="txt-bigger font-weight-light darkblue-color">
              {{ formatNumber(vat * 100, 2) }} %
            </v-flex>
          </v-layout> -->
          <div style="height: 200px"></div>
          <v-divider></v-divider>
          <v-layout class="mt-2" justify-space-between align-center>
            <v-flex shrink class="font-weight-bold">Total :</v-flex>
            <v-flex
              shrink
              class="font-weight-bold txt-head text-right darkblue-color"
            >
              {{ toCurrencyLable(currency.currency) }}
              {{ formatNumber(totalIncludedTax, 2) }}
              <br />
            </v-flex>
          </v-layout>
          <div class="mb-5">(VAT included)</div>
          <v-btn
            class="cust-btn-color"
            block
            :disabled="inCart.length <= 0"
            @click="$router.push({ name: 'Checkout' })"
            >{{$t('checkoutStep.checkout')}}</v-btn
          >
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import CartTable from '../components/CartTable'
import { mapState } from 'vuex'
import { formatNumber } from '@/plugins/global-funcs'
import Loading from '../components/Loading'
import {currency_symbol} from '../config'
import Service from '../services'

export default {
  name: 'Cart',
  metaInfo: {
    title: 'Thai Bike Part',
    titleTemplate: 'Cart |%s '
  },
  components: {
    CartTable,
    Loading
  },
  data() {
    return {
      currency_symbol:currency_symbol,
      isLoading: false,
      noAddressText: 'Calculate on next step',
      cartHeader: [
        {
          text: 'Product',
          value: 'product'
        },
        {
          text: 'Quantity',
          value: 'quantity',
          width: '140px',
          align: 'center'
        },
        {
          text: 'Total Price',
          value: 'total',
          width: '140px',
          align: 'center'
        },
        {
          text: '',
          value: 'remove',
          width: '60px',
          align: 'center'
        }
      ],
      deliveryCost: 5.0,
      formatNumber: formatNumber
    }
  },
  async created() {
    if (!this.accessToken) {
      this.$router.push({ name: 'Home' })
      this.$store.commit('Auth/loginDialogHandle', true)
    }
    this.isLoading = true
    await this.calculateShipping();
    this.isLoading = false
  },
  computed: {
    ...mapState({
      inCart: (state) => state.Cart.inCart,
      cartDetail: (state) => state.Cart.cartDetail,
      exchangeRate: (state) => state.Currency.exchangeRate,
      currency: (state) => state.Currency.current,
      shippingCost: (state) => state.Address.shippingCost,
      accessToken: (state) => state.Auth.userDetail.access_token,
      vat: (state) => state.Payment.vat
    }),
    subTotal: function () {
      let sum = 0.0
      for (const x of this.inCart) {
        let price = 
          this.specificExchangeRate(x.price_per_piece, this.currency.currency)
        
        sum += parseFloat(price * x.quantity, 2)
      }
      return parseFloat(sum) || parseFloat(sum) == 0 ? sum : '-'
    },
    totalIncludedTax: function () {
      let total
      if (this.shippingCost == 0) {
        total = parseFloat(this.subTotal)
      } else {
        total =
          parseFloat(this.subTotal) +
          this.specificExchangeRate(0, this.currency.currency)
      }
      return parseFloat(total) || parseFloat(total) == 0 ? total : '-'
    }
  },
  methods: {
    async calculateShipping() {
      let res = await Service.address.getAddress({
        access_token: this.accessToken
      })
      if (res.status === 'success' && res.data[0]) {
        await this.$store.dispatch('Address/onGetShippingCost', {
          cart_id: this.cartDetail.id,
          address_id: res.data[0].delivery.id
        })
      } else {
        this.$store.commit('Address/setShippingCost', 0)
      }
    },
    async increaseItem(product) {
      let partList = product
      partList.part_id = product.product_id
      partList.groupPart_id = product.group_part_id,
      partList.id = product.group_part_part_id,
      partList.amount = 1
      product = []
      product.push(partList)
      let item = {
        access_token: this.accessToken,
        productList: product
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/add', item)
      await this.calculateShipping();
      this.isLoading = false
    },
    async decreaseItem(product) {
      product.part_id = product.part.id
      product.amount = 1
      let item = {
        access_token: this.accessToken,
        productList: product
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/decreaseOne', item)
      await this.calculateShipping();
      this.isLoading = false
    },
    toCurrencyLable(currency) {
      return currency_symbol[currency]
    },
    toExchangeRate(thb_price) {
      return this.currency.sell_rate * thb_price
    },
    specificExchangeRate(thb_price, currency) {
      // return this.exchangeRate[currency].sell_rate * (thb_price ? thb_price : 0)
      if(currency === 'USD') return Math.ceil((this.exchangeRate[currency].sell_rate * (thb_price ? thb_price : 0)) * 10) / 10;
      else return Math.ceil(this.exchangeRate[currency].sell_rate * (thb_price ? thb_price : 0));
    },
    async removeItem(product) {
      product.part_id = product.part.id
      product.amount = 1
      let item = {
        access_token: this.accessToken,
        productList: product
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/removeItem', item)
      await this.calculateShipping();
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.table-head-theme {
  border: solid 1px var(--bkk-darkblue) !important;
}
</style>
